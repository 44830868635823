<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      <!-- LPCentre Web Application. V{{ version }}7.1.0 -->
      LPCentre Web Application. V7.1.1

    </span>

    <span class="float-md-right d-none d-md-block">Made with
      💙 
      By <a 
      target="_blank" href="https://www.prokoders.com">ProKoders</a>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue';
import pkg from '../../../../package.json';
export default {
  components: {
    BLink,
  },
  data() {
    return {
      version: pkg.version, // Get the version from package.json
    };
  },
};
</script>
